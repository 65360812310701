.shr-form-wrapper{
    margin: auto;
    form{
        display: block;
        label{
            display: block;
            text-align: left;
        }
        input{
            // border: 2px solid black;
            width: 100%;
            height: 46px;
            padding: 0 16px;
            outline: 0;
            transition: .8s;
            margin-bottom: 20px;
            &:focus{
                // background-color: rgb(212 212 216);
            }
        }
        .textarea{
            // border: 2px solid black;
            height: 46px;
            padding: 0 16px;
            outline: 0;
            transition: .8s;
            margin-bottom: 20px;
            width: 100%;
            min-height: 200px;
        }
    }
}