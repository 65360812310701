.header {
  background: rgba(0, 0, 0, 0.248);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  position: fixed;
  top: 0;
  z-index: 99999;
  width: 100%;

  .wrapper {
    align-content: center;
    align-items: center;
    justify-content: space-between;
  }
  .logo {
    max-width: 150px;
  }

  .nav-link {
    color: #fff;
    transition: 0.3s;
    cursor: pointer;
    width: 120px;

    &:hover {
      font-weight: 900;
    }
  }
}
