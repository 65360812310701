.footer {
  background: #131212;
  color: #fff;

  .footer-logo-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .footer-menu-wrapper {
    a {
      color: #fff;
      transition: 0.3s;
      cursor: pointer;
      width: 120px;

      &:hover {
        font-weight: 900;
      }
    }
  }
}
