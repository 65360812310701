body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hero-video {
  width: 100%;
}
.video-section {
  z-index: 11;
}
.main-section {
  /* background-image: url(./assets/img/bg.png); */
  background-color: #131212;
}
#particles-js,
.gradient {
  width: 100%;
  background: #131212;
}
.particles {
  height: 100vh;
  transform: translateZ(0);
}
.gradient {
  height: 200px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.gradient.gradient-1 {
  -webkit-transform: skew(0, -4.76deg) translateY(4.1666666667vw);
  transform: skew(0, -4.76deg) translateY(4.1666666667vw);
  opacity: 0.5;
}
.gradient.gradient-1.top {
  top: 0;
  -webkit-transform: skew(0, 4.76deg) translateY(1vw);
  transform: skew(0, 4.76deg) translateY(1vw);
}
.gradient.gradient-2 {
  -webkit-transform: skew(0, -4.76deg) translateY(6.25vw);
  transform: skew(0, -4.76deg) translateY(6.25vw);
}
.gradient.gradient-2.top {
  top: 0;
  -webkit-transform: skew(0, 4.76deg) translateY(-1.75vw);
  transform: skew(0, 4.76deg) translateY(-1.75vw);
}
canvas {
  display: block;
  vertical-align: bottom;
}
.centered-div {
  position: absolute;
  top: 50%;
  right: 50%;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}
.panel {
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.color-black {
  background-color: #131212;
  color: #fff;
}
.color-white {
  background-color: #fff;
  color: #131212;
}
#o-nas-1 {
  background-image: url(./assets/img/img.jpeg);
  background-size: cover;
  -webkit-clip-path: polygon(1% 0, 100% 0, 100% 82%, 1% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 84%, 0 100%);
  z-index: 9999;
}
#o-nas-1 h1 {
  font-size: 90px;
  color: #fff;
}

.slider .slide img {
  height: 100%;
}

.vertical-mid {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
