.scatterGallery {
  width: 620px;
  min-width: 40px;
  margin: 0 auto;

  ul {
    width: 620px;
    margin: 80px auto;
    list-style: none;
    float: left;
    padding: 10px;
  }

  li {
    width: 200px;
    float: left;
    margin: 0 10px 10px 0;
    // -webkit-filter: sepia(0.7);
    position: relative;
    -webkit-transition: 0.8s;
  }

  li:hover {
    -webkit-filter: sepia(0);
    z-index: 1;
  }

  li:nth-child(3n) {
    margin-right: 0;
  }

  li:nth-last-child(-n + 3) {
    margin-bottom: 0;
  }

  img {
    -webkit-transition: 0.8s;
    max-width: 100%;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    float: left;
    border: 8px solid white;
    border-bottom-width: 30px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  li:nth-child(1) img {
    -webkit-transform: translate(-10px, 20px) rotate(-10deg);
  }

  li:nth-child(2) img {
    -webkit-transform: translate(-20px, 70px) rotate(10deg);
  }

  li:nth-child(3) img {
    -webkit-transform: translate(-40px, 5px) rotate(13deg);
  }

  li:nth-child(4) img {
    -webkit-transform: translate(-20px, 20px) rotate(5deg);
  }

  li:nth-child(5) img {
    -webkit-transform: translate(-30px, 50px) rotate(-10deg);
  }

  li:nth-child(6) img {
    -webkit-transform: translate(-40px) rotate(5deg);
  }

  li img:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.7);
    -webkit-transform: scale(2);
  }
}
