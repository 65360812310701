.image-bg-scetion{
    height: 100vh;
    background-image: url(./10.JPG);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 100px;
    .text-wrap{
        background-color: rgba($color: #fff, $alpha: .85);
    }
}